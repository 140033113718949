.main{

}
.section{
    height: calc(100vh - 101px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 90px;
}
.section2{
    height: calc(100vh - 50px);
   
   
}
.title{
    margin-top: 80px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 50px;
    text-align: center;

}
@media only screen and (max-width: 460px) {
    .title{
        font-size: 38px;
}
}
.centerItem{
    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
}
.centerItem2{
    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    margin-top: auto;
    margin-bottom: 23px;
}
.subTxt{
    text-align: center;
    font-weight: 700;
    font-size: 30px;
}
@media only screen and (max-width: 600px) {
    .subTxt{
        font-size: 23px;
}
}
.blueGrad{
    background: 
linear-gradient(90.35deg, #28D0D0 15.73%, #2A8BE4 41.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.graph{
   max-height: 400px;
   max-width: 105vw;
   margin-bottom: 60px;
}
.graph2{
    max-height: 400px;
    max-width: 150vw; 
    z-index: -1;
    background-color: rgba(255, 0, 0, 0); 

  
 }

 .colCont{
     height: 100%;
   
    align-self: center;
    justify-self: center;
     width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    

 }


.leftCol{
    width: 564px;
    max-width: 90%;    
   
    z-index: 0;
       
}
.blueTitle{
    background: 
linear-gradient(90.35deg, #28D0D0 15.73%, #2A8BE4 41.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 60px;
}
@media only screen and (max-width: 640px) {
    .blueTitle{
        font-size: 48px;
}
}
@media only screen and (max-width: 460px) {
    .blueTitle{
        font-size: 36px;
}
}
.bT1{
    font-weight: 700;
    font-size: 36px;
}
@media only screen and (max-width: 557px) {
    .bT1{
        font-size: 32px;
}
}
@media only screen and (max-width: 460px) {
    .bT1{
        font-size: 30px;
}
}

.bT2{
    font-weight: 700;
    font-size: 21px;
}
@media only screen and (max-width: 557px) {
    .bT2{
        font-size: 18px;
}
}
@media only screen and (max-width: 460px) {
    .bT2{
        font-size: 16px;
}
}
.arrow{
    margin-top: auto;
    margin-bottom: 0;
}
.txtCont{
    margin-top: 200px;
    margin-bottom: 200px;
}
.txt{
    font-weight: 400;
    font-size: 42px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.subTxt2{
    font-size: 14px;
    font-weight: 400;
    color: #0062CC;
    display: flex;
    justify-content: center;
    margin-top: 23px;
    cursor: pointer;
}

.centerApps{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.appCont{
    width: 96%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    min-height: max-content;
    height: auto;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
}

.purpleBox{
    width: 100%;
    height: 550px;
    border-radius: 50px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.promoteBox{
    border-radius: 50px;
    height: 550px;
    width: 100%;   
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-top: 50px;
    padding-top: 30px;
    max-width: 580px;
}
.identityBox{
    border-radius: 50px;
    height: 550px;
    width: 100%;  
    display: flex;
    flex-direction: column;
    min-width: 380px;
    margin-top: 50px;
    padding-top: 30px;
    max-width: 580px;
}
.lTxtCont{
    width: 80%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;

}
.wTxt{
    color: white;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 9px;
}
@media only screen and (max-width: 460px) {
    .wTxt{
        font-size: 21px;
}
}
.sTxt{
    font-weight: 700;
    font-size: 42px;
    color: white;
    margin-bottom: 21px;
    line-height: 50px;
}
@media only screen and (max-width: 460px) {
    .sTxt{
        font-size: 33px;
        width: 80%;
}
}
.lTxt{
    font-size: 18px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.splitRow2{
    width: 100%;
    display: flex;
    flex-wrap: wrap;   
    justify-content: space-evenly; 
}
.fContainer{
    align-self: center;
   margin-top: 60px;
     width: 100%;
     max-width: 98%;
     display: flex;
     justify-content: center;
     z-index: 1;
 }
 .footer{
     justify-self: center;
     align-self: center;
     font-size: 16px;
     font-weight: 400;
     line-height: 15px;
     color: black;
     display: flex;
     width: 100%;
     max-width: 380px;
     justify-content: space-evenly;
     margin-bottom: 23px;
 }