.main{
   
}
.page{
    width: 100%;
    position: absolute;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: max-content;
   
    align-items: center;
   
}
.title{
    font-size: 33px;
    font-weight: 700;
    margin-top: 36px;
   text-align: center;

}
@media only screen and (max-width: 690px) {
    .title{
        font-size: 23px;
        margin-top: 30px;
    }
}

.chat{
    margin-top: 23px;
    margin-bottom: -12px;
   

}
.planPic { 
    height: 180px;
    max-height: 180px;
    margin-right: 50px;
    margin-left: 50px;
}
.txtCont{
    margin-top: 16px;

}
.txtCont2{
    margin-top: 200px;
}

.txt{
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
}
.txt2{
    font-weight: 400;
    font-size: 42px;
    margin-top: 12px;
    text-align: center;
    margin-left: 6%;
    margin-right: 6%;
}
@media only screen and (max-width: 460px) {
    .txt2{
        font-size: 30px;
  
}
}
.txtP{
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 6px;
}
.subTxt{
    font-size: 14px;
    font-weight: 400;
    color: #0062CC;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    cursor: pointer;
}
.subTxt2{
    font-size: 14px;
    font-weight: 400;
    color: #0062CC;
    display: flex;
    justify-content: center;
    margin-top: 23px;
    margin-bottom: 200px;
    cursor: pointer;
}

.planT{
    font-weight: 700;
    font-size: 27px;
    margin-top: 30px;
}
.rows{
   
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 920px;
    columns: 3;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    max-width: 98%;
    padding-bottom: 100px;
}
@media only screen and (max-width: 840px) {
    .rows{
        grid-template-columns: repeat(1, 1fr);
    }
}
.column{
    margin-top: 60px;
    max-width: 280px;
    height: 620px;
    display: flex;
    flex-direction: column;
    margin-left: 3px;
    margin-right: 3px;

}
.start{
  
    
    font-size: 12px;
    font-weight: 400;
}
.price{
    font-weight: 700;
    font-size: 24px;
}
.rBtn{
    font-weight: 400;
    font-size: 16px;
    color: white;
    height: 27px;
    border-radius: 6px;
    background-color: #0062CC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
    line-height: 27px;
    cursor: pointer;
    

}
.lBtn{
    font-weight: 400;
    font-size: 16px;
    color: white;
    height: 27px;
    border-radius: 6px;
    background-color: #636363;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
    line-height: 27px;
    cursor: pointer;
    
}
.subCont{
   
   margin-top: auto;
}
.mediaContainer{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    width: 920px;
    max-width: 90%;
}
.splitRow{
    width: 100%;
    display: flex;
    height: 300px;
    justify-content: space-between;
    margin-bottom: 12px;
}
.splitRow2{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
   
    justify-content: space-evenly;
 
}
.twoThirdBox{
    height: 100%;
    background-color: #Fbfbfb;
    border-radius: 23px;
   
    min-width: 64%;
    max-width: 66.6%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-evenly;
    
    margin-left: 6px;
    margin-right: 6px;
    z-index: -2;
    
    
}
.oneThirdBox{
    height: 100%;
    background-color: #Fbfbfb;
    border-radius: 23px;
    
    display: flex;
    flex-direction: column;
    
    overflow: hidden;
    justify-content: space-evenly;
    margin-left: 6px;
    margin-right: 6px;
   

}
.fullCont{
    
   height: 320px;

    width: 100%;
    background-color: #Fbfbfb;
    border-radius: 23px;
  
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 12px;
    padding-top: 16px;
    padding-bottom: 16px;

}

.boldTxt{
    font-size: 18px;
    font-weight: 550;
    color: #4f4f4f;
    
    justify-self: center;

}
@media only screen and (max-width: 690px) {
    .boldTxt{
        font-size: 12px;
    }
}
.boldTxtLeft{
    font-size: 18px;
    font-weight: 550;
    color: #4f4f4f;
    
    justify-self: center;
    text-align: left;

}
@media only screen and (max-width: 690px) {
    .boldTxtLeft{
        font-size: 12px;
    }
}
.darkBoldTxt { 
    color: #000000;
}

.lightTxt{
    font-size: 18px;
    font-weight: 700;
    color: #636363;
    width: 100%;
}
.imgC{
   max-height: 66%;
   width: 70%;
  
   display: flex;
   flex-direction: column;
   justify-self: center;
   align-self: center;
   align-items: center;


}
.imgC2{
    max-height: 90%;
    width: 100%;
   
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    align-items: center;
 
 
 }
.img{
    max-width: 100%;
    height: auto;
    max-height: 100%;
}
.img4{
 margin-left: 30%;
 margin-top: -30%;
 width: 120%;
 z-index: -1;
}

.txtRow{
    display: flex;
    flex-direction: column;
   padding-left: 6px;
   padding-right: 6px;
   align-self: center;
   text-align: center;
   max-width: 80%;
  
}
.txtRow4{
    display: flex;
    flex-direction: column;
   padding-left: 6px;
   padding-right: 6px;
   max-width: 80%;
   margin-top: 30px;
   margin-left: 30px;
  
}

.appCont{
    width: 96%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    min-height: max-content;
    height: auto;
}

.purpleBox{
    width: 100%;
    height: 550px;
    border-radius: 50px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;


}
.promoteBox{
    border-radius: 50px;
    height: 550px;
    width: 100%;
   
    display: flex;
    flex-direction: column;

    min-width: 380px;
    margin-top: 50px;
    padding-top: 30px;
    max-width: 580px;
   
  

}
.identityBox{
    border-radius: 50px;
    height: 550px;
    width: 100%;
  
    display: flex;
    flex-direction: column;

    
    min-width: 380px;
    margin-top: 50px;
    padding-top: 30px;
    max-width: 580px;

}
.lTxtCont{
    width: 400px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;

}
.wTxt{
    color: white;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 9px;
}
@media only screen and (max-width: 460px) {
    .wTxt{
        font-size: 21px;
}
}
.sTxt{
    font-weight: 700;
    font-size: 42px;
    color: white;
    margin-bottom: 21px;
}
@media only screen and (max-width: 460px) {
    .sTxt{
        font-size: 33px;
        width: 80%;
}
}
.lTxt{
    font-size: 18px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}

.fContainer{
   align-self: center;
  margin-top: 60px;
    width: 380px;
    max-width: 98%;
    display: flex;
    justify-content: center;
    z-index: 1;
}
.footer{
    justify-self: center;
    align-self: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    color: #000000;
    display: flex;
    width: 100%;
    max-width: 380px;
    justify-content: space-evenly;
    margin-bottom: 23px;
}
