.main{
    margin-top: 0;
   top: 0;
  overflow-y: visible;
  max-width: 100vw;
  overflow-x: hidden;
}
.box{
    position: absolute;
    margin-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calendy{
    margin-top: auto;
    width: 90%;
    margin-bottom: 10px;
}
.calendyBox{
    margin-top: 10px;
    width: 100%;
    height: 900px;
    margin-bottom: 10px;
}
@media only screen and (max-width: 1000px) {
    .calendyBox{
    height: 1200px;
    margin-top: 10%;
}
}
.img{
    align-self: center;
   height: 225px;
   width: auto;
   margin-top: 20px;
   margin-bottom: 30px;
}
.title{
    text-align: center;
    font-weight: 700;
    font-size: 30px;
}
.rNow{
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: -20px;
}
@media only screen and (max-width: 360px) {
    .rNow{
        font-size: 26px;
        
    }
}