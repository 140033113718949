.main{
    top: 0;
    margin-top: 0;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow-x: hidden;
    background-color: black;
    scroll-snap-type: y mandatory;
    scrollbar-width: 0;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.tile1{
    height: 800px;
    max-width: 100vw;
    position: relative; 
}
@media only screen and (max-width: 600px) {
    .tile1{
        height: 600px;
    }
}
.tile2{
    height: 1000px;
    max-width: 100vw;
    position: relative; 
}
@media only screen and (max-width: 600px) {
    .tile2{
        height: 600px;
    }
}
.container{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
}

.webHero{
    width: 848px;
    max-width: 96%;

}

.txtRow{
    display: flex;
    white-space: pre-wrap;
    justify-content: center;
    margin-top: 6vh;
  
}

.sentence{
    color: white;
    font-size: 3.5vw;
    font-weight: 700;
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}
@media only screen and (max-width: 450px) {
    .sentence{
        font-size: 6vw;
    }
}
.redInternet{
    background: linear-gradient(90.66deg, #FF5C00 78.05%, #F92D00 99.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.txt33Red{
    
    font-size: xx-large;
    font-weight: 700;
    background: linear-gradient(91.03deg, #FF4100 79.71%, #F90E00 99.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.cDesignPic{
    position: relative;
    top: 130px;
    width: 960px;
    left: calc(50% - 960px/2);
}
@media only screen and (max-width: 600px) {
    .cDesignPic{
        width: 310px;
        left: calc(50% - 310px/2);
        top: 70px;
    }
}
.upTime{
    position: absolute;
    width: 622.84px;
    height: 600px;
    left: calc(50% - 622.84px/2 + 0.42px);
    top: 179px;
}
@media only screen and (max-width: 630px) {
    .upTime{
        width: 350px;
        height: 337.17px;
        left: calc(50% - 350px/2 + 9px);
        
    }
}
.rainbowContainer{
    position: relative;
    z-index: 1;
    width: 571px;
    max-width: 96%;
    height: 221px;
    left: calc(50% - 571px/2 + 164.5px);
}
.cdContainer{
    position: relative; 
    width: 500px;
    height: 221px;
    left: calc(50% - 571px/2 + 164.5px);
    top: 80px;
}
@media only screen and (max-width: 850px) {
    .cdContainer{
        width: 400px;
    }
}
@media only screen and (max-width: 600px) {
    .cdContainer{
        position: relative;
        width: 80%;
        height: 165px;
        left: calc(50% - 279px/2 + 0.5px);
    }
}

.rainbowTitle{
    position: relative;
    font-weight: 600;
    font-size: 42px;
    color: #EFEFEF;
}
@media only screen and (max-width: 775px) {
    .rainbowTitle{
     font-size: 21px;
     line-height: normal;
    }
}

.rainbow{
    font-weight: 600;
    font-size: 42px;
    margin-bottom: 1vh;
    
    background: linear-gradient(90.69deg, #FF3C3C -3.79%, #FFF73C 63.7%, #8EFF49 102.42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@media only screen and (max-width: 600px) {
    .rainbow{
     font-size: 30px;
     line-height: normal;
    }
}

.rainbow2{
    font-weight: 600;
    font-size: 36px;
    background: linear-gradient(91.13deg, #A105BA -8.46%, #D104BF 32.87%, #FF5C44 73.51%, #FF9E45 108.12%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
@media only screen and (max-width: 775px) {
    .rainbow2{
     font-size: 24px;
    }
}

.rainbowSubTxt{
    width: 90%;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 18px; 
    
    color: #F0F0F0;
}
@media only screen and (max-width: 775px) {
    .rainbowSubTxt{
     font-size: 15px;
    }
}

.proWebTitile{
    position: relative;
    top: 110px;
    text-align: center;
    font-weight: 600;
    font-size: 3.5vw; 
    color: #FFFFFF;
}
@media only screen and (max-width: 600px) {
    .proWebTitile{
        top: 50px;
        font-size: 6vw;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.rainbowContainerTL{
    position: relative;
    margin-left: 10%;
    top: 50px;
    margin-right: auto;
}

.rainbowContainerTR{
    position: absolute;
    width: 489px;
    height: 163px;
    left: calc(50% - 489px/2 + 206.5px);
    top: 134px;
}
@media only screen and (max-width: 775px) {
    .rainbowContainerTR{
        width: 300px;
        top: 180px;
    }
}
@media only screen and (max-width: 560px) {
    .rainbowContainerTR{
        width: 90%;
        left: 5%;
        top: 50px;
    }
}
.cssPic{ 
    position: absolute;
    width: 1058px;
    height: 732.03px;
    left: calc(50% - 1058px/2);
    top: 134px;
}
@media only screen and (max-width: 1000px) {
    .cssPic{
        width: 100%;
        height: auto;
        left: 0%;
        top: 200px;
        position: relative;
    }
}
.rightBox{
    padding-left: 12px;
    padding-bottom: 23px;
    border-radius: 3px;
    margin-left: auto;
}

.unlBandContainer{ 
    position: relative;
    top: 90px;
    width: max-content;
    left: 20%;
    width: 80%;
    max-width: 500px;
    margin-right: 10px;
}
@media only screen and (max-width: 600px) {
    .unlBandContainer{
        left: 30px;
        width: 80%;
    }
}
.unlBand{ 
    font-weight: 600;
    font-size: 45px;
    background: linear-gradient(91.07deg, #DB4F00 0.32%, #AE10E6 108.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
@media only screen and (max-width: 600px) {
    .unlBand{
        font-size: 27px;
    }
}
.unlBandTxt{ 
    max-width: 400px;
    margin-top: 3px;    
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    color: #F0F0F0;
}
@media only screen and (max-width: 600px) {
    .unlBandTxt{
        font-size: 12px;
        max-width: 269px;
    }
}
.techSpecTxt{ 
    margin-top: 9px;

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #F0F0F0;
}
@media only screen and (max-width: 600px) {
    .techSpecTxt{
        font-size: 12px;
    }
}
.techSpecLink{ 
    color: #0062cc;
    cursor: pointer;
}

.learnMoreTxt{ 
    margin-top: 21px;

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #F0F0F0;
    cursor: pointer;
}
@media only screen and (max-width: 600px) {
    .learnMoreTxt{
        font-size: 12px;
    }
}
.unlBandPic { 
    position: relative;
    width: 473px;
    left: calc(50% - 473px/2 + 0.5px);
    top: 120px;
}
@media only screen and (max-width: 600px) {
    .unlBandPic{
        width: 225px;
        height: auto;
        left: calc(50% - 225px/2 + 0.5px);
        top: 130px;
    }
}

.whtTitle{  
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    color: #F0F0F0;
}
@media only screen and (max-width: 660px) {
    .whtTitle{
        font-size: 33px;
}
}
.colTitleBuy{
    width: max-content;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    background: linear-gradient(91.05deg, #3083FF -2.52%, #34FF79 102.83%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
@media only screen and (max-width: 660px) {
    .colTitleBuy{
        font-size: 24px;
        max-width: 90%;
}
}
.colTitleJam{
    width: max-content;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    background: linear-gradient(91.05deg, #FF6E30 -2.52%, #FBFF4B 32.6%, #39FFB8 67.71%, #0057FF 102.83%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
@media only screen and (max-width: 660px) {
    .colTitleJam{
        font-size: 24px;
        max-width: 90%;
}
}
.bodyTxt{
    max-width: 400px;
    margin-top: 3px;    
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #F0F0F0;
}
@media only screen and (max-width: 660px) {
    .bodyTxt{
        font-size: 12px;
}
}
.infoContainer{ 
    position: relative;
    top: 100px;
    width: max-content;
    left: 10%;
    width: 80%;
    max-width: 500px;
    margin-right: 10px;
}

.secPic{ 
    position: relative;
    width: 900px;
    left: calc(50% - 900px/2);
    top: 150px;
}
@media only screen and (max-width: 960px) {
    .secPic{
        width: 90%;
        height: auto;
        left: 5%;
        top: 130px;
    }
}
.pricingPlans{ 
    height: 900px;
}
@media only screen and (max-width: 930px) {
    .pricingPlans{
        height: 2300px;
    }
}
.txt{
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
    color: #F0F0F0;

}
.txtP{
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 6px;
    color: #F0F0F0;
}
.rows{
    top: 120px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    columns: 3;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    max-width: 98%;
    margin-left: 10px;
}
@media only screen and (max-width: 930px) {
    .rows{
        grid-template-columns: repeat(1, 1fr);
    }
}
.column{
    margin-top: 60px;
    max-width: 280px;
    height: 620px;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;

}
.planT{
    font-weight: 700;
    font-size: 27px;
    color: white;
    
}
.subCont{
   
    margin-top: auto;
 }
 .start{
  
    color: #F0F0F0;
    font-size: 12px;
    font-weight: 400;
}
.price{
    font-weight: 700;
    font-size: 24px;
    color: white;
}
.rBtn{
    font-weight: 400;
    font-size: 16px;
    color: white;
    height: 27px;
    border-radius: 6px;
    background-color: #0062CC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
    line-height: 27px;
    cursor: pointer;
    

}
.lBtn{
    font-weight: 400;
    font-size: 16px;
    color: white;
    height: 27px;
    border-radius: 6px;
    background-color: #636363;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
    line-height: 27px;
    cursor: pointer;
    
}

.largeBoxSection{
    height: 600px;
}

.largeBox{ 
    position: relative;
    width: 90%;
    height: 550px;
    left: 5%;
}
.centerApps{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.appCont{
    width: 96%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    min-height: max-content;
    height: auto;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
}

.purpleBox{
    width: 100%;
    height: 550px;
    border-radius: 50px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;


}
.promoteBox{
    border-radius: 50px;
    height: 550px;
    width: 100%;
   
    display: flex;
    flex-direction: column;

    min-width: 380px;
    margin-top: 50px;
    padding-top: 30px;
    max-width: 580px;
   
  

}
.identityBox{
    border-radius: 50px;
    height: 550px;
    width: 100%;
  
    display: flex;
    flex-direction: column;

    
    min-width: 380px;
    margin-top: 50px;
    padding-top: 30px;
    max-width: 580px;

}
.lTxtCont{
    width: 400px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;

}
.wTxt{
    color: white;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 9px;
}
@media only screen and (max-width: 460px) {
    .wTxt{
        font-size: 21px;
}
}
.sTxt{
    font-weight: 700;
    font-size: 42px;
    color: white;
    margin-bottom: 21px;
}
@media only screen and (max-width: 460px) {
    .sTxt{
        font-size: 33px;
        width: 80%;
}
}
.lTxt{
    font-size: 18px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}

.fContainer{
   align-self: center;
  margin-top: 60px;
    width: 100%;
    max-width: 98%;
    display: flex;
    justify-content: center;
    z-index: 1;
}
.footer{
    justify-self: center;
    align-self: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    color: white;
    display: flex;
    width: 100%;
    max-width: 380px;
    justify-content: space-evenly;
    margin-bottom: 23px;
}
.splitRow2{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
   
    justify-content: space-evenly;
 
}

.askPlanSection{ 
    height: 600px;
}
.askPlan{
    position: relative;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    color: white;
    text-align: center;
    top: 260px;
}
@media only screen and (max-width: 460px) {
    .askPlan{
        font-size: 30px;
        margin-left: 6%;
        margin-right: 6%;
}
}
.compareTxt{
    position: relative;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #0062CC;
    text-align: center;
    top: 280px;
    cursor: pointer;
}
@media only screen and (max-width: 460px) {
    .compareTxt{
        font-size: 12px;
}
}