.main{
    width: 100vw;
    height: 100px;
    position: absolute;
   
  max-width: 100vw;
  overflow-x: hidden;
   
    display: flex;
    justify-content: center;
    z-index: 1;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    border-bottom: #636363 .6px solid;


}


.column{
    width: 100%;
    max-width: 730px;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.topSpace{
    height: 50px;

}
.row{
    justify-self: center;
    display: flex;
    width: 100%;
    max-width: 730px;
    justify-content: space-between;
    align-items: center;
}
.btnRow{
    display: flex;
    min-width: 130px;
    width: 33vw;
    max-width: 130px;
    justify-content: space-between;
    padding-left: 6px;
    align-items: center;
    margin-right: 12px;
}
.title{
    margin-left: 9px;
    height: 25px;
    font-size: 18px;
    font-weight: 700;
    flex-wrap: nowrap;
}
@media only screen and (max-width: 440px) {
    .title{
        font-size: 18px;
        max-width: 100px;
        overflow: hidden;

    }
}
.btnTxt{
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    cursor: pointer;
   
}
.requestBtn{
    font-size: 12px;
    font-weight: 400;
    background-color: #0062CC;
    color: white;
    height: 22px;
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    justify-content: center;
    border-radius: 6px;
    line-height: 20px;
    cursor: pointer;
}