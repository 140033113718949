.main{
    width: 100vw;
    max-width: 100vw;
    height: 50px;
    margin-top: 0;

   background: rgba(0, 0, 0 , 0.9);
   backdrop-filter: blur(4.5px);
    position: fixed;
    display: flex;
    z-index: 2;
    justify-content: center;
  
   
   
}
.row{
    width: 100%;
    
    padding-left: 6px;
    padding-right: 6px;
    align-self: center;
   
    height: 50px;
    max-width: 730px;
   
    color: #FFFFFF;
 display: flex;
 align-items: center;

justify-content: space-between;
}
.logo{   
 
    padding-left: min(16px, 1.0%);
    height: 32px; 
    cursor: pointer; 
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
      justify-self: left;
    max-width: 134px;
  
 
   
  
}
.falseDiv{
    padding-right: min(16px, 1.0%);
    width: 134px;
  
  

}
.rightSide{
   align-self: center;
  justify-self: center;
    

}
.pages{
    flex-basis: 1;
    display: flex;
   height: 32px;
      
   
    width: 80%;
    max-width: 536px;
    align-items: center;
    justify-content: space-between;
    justify-self: center;
   
   
    

}

.page{
   
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
   cursor: pointer;
  
   height: 24px;
   align-content: center;
   align-items: center;
   
   padding-left: 9px;
   padding-right: 9px;
   padding-top: 3px;
   padding-bottom: 3px;

}
.page:hover{
    background-color: rgba(0, 0, 0, 0.3);
   border-radius: 3px;
}
.mobileMenu{
    margin-right: 9px;
    cursor: pointer;
}



.search{
   padding-right: min(16px, 1.5%);
margin-left: auto;
    
    
}
.bg{ 
    color: rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(4.5px);
    position: absolute;
    width: 100vw;
    max-width: 100vw;
    height: calc(var(--vh, 1vh) *100);
    
    z-index: 1;
}
.dropDown{
    background-color: white;
    position: absolute;
    margin-top: 0;
    right: 0;
    width: 80vw;
    max-width: 300px;
    z-index: 2;
    height: calc(var(--vh, 1vh) *100);
    display: flex;
    flex-direction: column;

}

.iconCont{
    width: 100%;
    display: flex;
    

}
.close{
    color: black;
    height: 24px;
    margin-left: auto;
    margin-right: 16px;
    margin-top: 16px;
    cursor: pointer;
}
.page2{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
   cursor: pointer;
   color: black;
   width: 80%;
   align-self: center;
   justify-self: center;
   padding-left: 12px;
   margin-top: 12px;
   line-height: 32px;
   height: 32px;

}
.page2:hover{
    background-color: #F0F0F0;
 
   border-radius: 6px;
}
.logo2{
    margin-top: auto;
    width: 160px;
    justify-self: center;
    align-self: center;
    margin-bottom: 30px;
    
}

