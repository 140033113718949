.main{
  

}
.page{
    position: absolute;
    margin-top: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
   
}
.specCont{
    justify-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 800px;
    max-width: 96%;
   
}

.specsT{
    background: linear-gradient(93.88deg, #24A3EA -2.95%, #115FDE 102.42%);
    -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
    font-size: 80px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: -10px;
    height: 80px;
}
.deskT{
    width:800px;
    max-width: 96%;
}
.glance{
    font-size: 27px;
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 50px;
}
.line{
    margin-top: 50px;
    margin-bottom: 23px;
    width: 800px;

    max-width: 60%;

    border: 1px solid #CCCCCC;
}
.container{
    width: 800px;
    max-width: 96%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    columns: 3;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    
   
   
}
@media only screen and (max-width: 712px) {
    .container{
        grid-template-columns: repeat(1, 1fr);
    }
}
.iconCont{
  margin-bottom: 50px;
  height: 204px;
  max-width: 236px;
}
.txtCont{
    margin-top: 200px;
    width: 100vw;

}

.txt{
    font-size: 42px;
    text-align: center;
    margin-left: 6%;
    margin-right: 6%;
}
@media only screen and (max-width: 460px) {
    .txt{
        font-size: 30px;
}
}
.subTxt{
    font-size: 12px;
    font-weight: 400;
    color: #0062CC;
    display: flex;
    justify-content: center;
    margin-top: 23px;
    cursor: pointer;
}
.fContainer{
    margin-top: 200px;
    width: 380px;
    max-width: 98%;
    display: flex;
    justify-content: center;
}
.footer{
    justify-self: center;
    align-self: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    color: #000000;
    display: flex;
    width: 100%;
    max-width: 380px;
    justify-content: space-evenly;
    margin-bottom: 23px;
}
