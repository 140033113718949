.main{
    top: 0;
    margin-top: 0;

}

.page{
    width: 100%;
    
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
   
    align-items: center;
   
}
.tile1{
    height: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: max-content;
   
    align-items: center;
}
.container{
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: max-content;
    margin-top: 130px;
    align-items: center;
}
.title{
    font-weight: 700;
    font-size: 50px;
    color: white;
    margin-bottom: 15px;
}
.chat{
    width: 42px;
    margin-bottom: 9px;
}
.whtTxt{
    font-weight: 400;
    font-size: 15px;
    color: white;
    margin-bottom: 6px;
}
.blueTxt{
    font-weight: 400;
    font-size: 15px;
    color: #006ADD;
}
.calendy{
    margin-top: auto;
    width: 90%;
    margin-bottom: 10px;
}
.calendyBox{
    margin-top: auto;
    width: 100%;
    height: 900px;
    margin-bottom: 10px;
}
@media only screen and (max-width: 1000px) {
    .calendyBox{
    height: 1200px;
}
}