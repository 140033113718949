.main{
    top: 0;
    margin-top: 0;

}
.brandingHero{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
    position: relative;
    height: auto;
}
.logo{ 
    position: relative;
    max-width: 550px;
    width: 80%;
    height: auto;
    margin-bottom: 60px;
    margin-top: 60px;
}
.heroTitle{ 
    position: relative;
    font-weight: 600;
    font-size: 4vw;
    text-align: center;
    position: relative;
    color: #2C2C2E;
}
.fuIdTxt{ 
    background: linear-gradient(92.54deg, #0067D8 9.9%, #5D22DA 52.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}
.arrow{ 
    position: relative;
    width: 6vw;
    max-width: 40px;
    margin-top: 40px;
    margin-bottom: 9px;
}
.yourTile{ 
    height: 36vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-top: 10%;
    padding-bottom: 10%;
    
}
.yourContents{ 
    position: relative;
    width: 76%;
    left: 11%;
    min-height: 120px;
    height: fit-content;
    top: 0;
}
.yourTxt{
    position: absolute;
    font-weight: 800;
    font-size: 6.5vw;

    background: linear-gradient(94.39deg, #2AA2E6 -0.85%, #CD00B9 87.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.aApic{ 
    position: absolute;
    width: 43%;
    right: 0;
    top: 0;
}
.perspectiveTile{ 
    height: 50vw;
    position: relative;
    margin-top: 130px;
    margin-bottom: 90px;
}
@media only screen and (max-width: 580px) {
    .perspectiveTile{
    height: 60vh;
    }
}
.persCont{ 
    position: relative;
    max-width: 600px;
    margin-left: 40%;
    margin-right: 30px;
    
}
@media only screen and (max-width: 580px) {
    .persCont{
    margin-left: 40px;
    margin-top: 60px;
    }
}
.persTitle{
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 6px;
    color: #2C2C2E;
}
.perspective{ 
    background: linear-gradient(90.57deg, #E02424 31.59%, #FF7E07 61.01%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: auto;
}
.persTxt{ 
    line-height: 24px;
    font-size: 15px;
    color: #2C2C2E;
}
.linePic{ 
    position: relative;
    left: 0;
    top: -200px;
    z-index: -1;
    min-width: 608px;
    min-height: auto;
    width: 80%;
    max-width: 1100px;
}
@media only screen and (max-width: 580px) {
    .linePic{
     top: 0px;
    }
}
.checkTile{ 
    
    
    display: flex; 
    flex-direction: column;
    justify-content: center;
}

.compTitle{ 
    font-weight: 700;
    font-size: 36px;
    color: #2C2C2E;
}
@media only screen and (max-width: 550px) {
    .compTitle{
    font-size: 6vw;
    }
}
.bsTitle{ 
    font-weight: 700;
    font-size: 66px;

    background: linear-gradient(90.71deg, #02A626 -1.16%, #1A9DC6 102.42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    margin-bottom: 12px;
}
@media only screen and (max-width: 550px) {
    .bsTitle{
    font-size: 12vw;
    }
}
.checkTxt{ 
    line-height: 24px;
    font-size: 21px;
}
@media only screen and (max-width: 550px) {
    .checkTxt{
    font-size: 15px;
    }
}
.roadmapPic{
    position: relative;
    width: 90%;
    margin-left: 5%;
    margin-top: 60px;
}
@media only screen and (max-width: 1200px) {
    .roadmapPic{
   
    }
}
.splitRow{
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1000px;
    height: fit-content;
    margin-top: 1px;
    margin-bottom: 100px;
}
@media only screen and (max-width: 1200px) {
    .splitRow{
     height: 800px;
    }
}
.checkCont{ 
    max-width: 490px;
  
}
@media only screen and (max-width: 1200px) {
    .checkCont{
    margin-top: 0px;
    margin-left: 10%;
    }
}
@media only screen and (max-width: 550px) {
    .checkCont{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    }
}
.checklistPic{ 
    justify-self: center;
    align-self: center;
    height: 100%;
   
 
}
@media only screen and (max-width: 1200px) {
    .checklistPic{
  /*  width: 30%;
    margin-right: 20px;
    margin-left: 65%;*/
   
    max-height: 450px;
    }
}
@media only screen and (max-width: 880px) {
    .checklistPic{/*
    width: 70%;
    margin-left: 15%;
    top: 40px;*/
    }
}
.cdTile{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    height: auto;
}
.cdTitle{
    width: 550px;
    max-width: 97vw;
    font-weight: 700;
    font-size: 66px;
    background: linear-gradient(90.71deg, #F25077 -1.16%, #F28016 102.42%);
    -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;

}
@media only screen and (max-width: 572px) {
    .cdTitle{
        font-size: 36px;
   
    }
}

.cdTxt{
    font-size: 21px;
    font-weight: 700;
    align-self: center;
    justify-self: center;
    
    width: 550px;
    max-width: 97vw;
}
@media only screen and (max-width: 572px) {
    .cdTxt{
        font-size: 16px;
  
    }
}
.spectImg{
    margin-top: 100px;
    margin-bottom: 100px;
  
    
}
.spectivFullPic{
    min-height: 150px;
    max-height: 300px;
    max-width: 100vw;
}

/* Plans CSS START */
.pricingPlans{ 
    height: 900px;
}
@media only screen and (max-width: 930px) {
    .pricingPlans{
        height: 2300px;
    }
}
.rows{
    top: 120px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    columns: 3;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    max-width: 98%;
    margin-left: 10px;
}
@media only screen and (max-width: 930px) {
    .rows{
        grid-template-columns: repeat(1, 1fr);
    }
}
.column{
    margin-top: 60px;
    max-width: 280px;
    height: 620px;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;

}
.start{
  
    
    font-size: 12px;
    font-weight: 400;
}
.price{
    font-weight: 700;
    font-size: 24px;
}
.rBtn{
    font-weight: 400;
    font-size: 16px;
    color: white;
    height: 27px;
    border-radius: 6px;
    background-color: #0062CC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
    line-height: 27px;
    cursor: pointer;
    

}
.lBtn{
    font-weight: 400;
    font-size: 16px;
    color: white;
    height: 27px;
    border-radius: 6px;
    background-color: #636363;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
    line-height: 27px;
    cursor: pointer;
    
}
.subCont{
   
   margin-top: auto;
}
.planT{
    font-weight: 700;
    font-size: 27px;
    margin-top: 30px;
    
}
.txtP{
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 6px;
}
.txt{
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
}
.planPic { 
    height: 180px;
    max-height: 180px;
    margin-right: 50px;
    margin-left: 50px;
}
/* Plans CSS END */


/* App Blocks START */
.centerApps{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.appCont{
    width: 96%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    min-height: max-content;
    height: auto;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
}

.purpleBox{
    width: 100%;
    height: 550px;
    border-radius: 50px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;


}
.promoteBox{
    border-radius: 50px;
    height: 550px;
    width: 100%;
   
    display: flex;
    flex-direction: column;

    min-width: 380px;
    margin-top: 50px;
    padding-top: 30px;
    max-width: 580px;
   
  

}
.identityBox{
    border-radius: 50px;
    height: 550px;
    width: 100%;
  
    display: flex;
    flex-direction: column;

    
    min-width: 380px;
    margin-top: 50px;
    padding-top: 30px;
    max-width: 580px;

}
.lTxtCont{
    width: 400px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;

}
.wTxt{
    color: white;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 9px;
}
@media only screen and (max-width: 460px) {
    .wTxt{
        font-size: 21px;
}
}
.sTxt{
    font-weight: 700;
    font-size: 42px;
    color: white;
    margin-bottom: 21px;
}
@media only screen and (max-width: 460px) {
    .sTxt{
        font-size: 33px;
        width: 80%;
}
}
.lTxt{
    font-size: 18px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.splitRow2{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
   
    justify-content: space-evenly;
 
}
/* App Blocks END */


/* Ask Plan START */ 
.askPlanSection{ 
    height: 600px;
}
.askPlan{
    position: relative;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    color: #000000;
    text-align: center;
    top: 260px;
    margin-left: 6%;
    margin-right: 6%;
}
@media only screen and (max-width: 460px) {
    .askPlan{
        font-size: 30px;
  
}
}
.compareTxt{
    position: relative;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #0062CC;
    text-align: center;
    top: 280px;
    cursor: pointer;
}
@media only screen and (max-width: 460px) {
    .compareTxt{
        font-size: 12px;
}
}
/* Ask Plan END */


/* Footer START */
.fContainer{
    align-self: center;
   margin-top: 60px;
     width: 100%;
     max-width: 98%;
     display: flex;
     justify-content: center;
     z-index: 1;
 }
 .footer{
     justify-self: center;
     align-self: center;
     font-size: 16px;
     font-weight: 400;
     line-height: 15px;
     color: #000000;
     display: flex;
     width: 100%;
     max-width: 380px;
     justify-content: space-evenly;
     margin-bottom: 23px;
 }
/* Footer END */