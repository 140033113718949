.main{
    top: 0;
    margin-top: 0;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow-x: hidden;

    scroll-snap-type: y mandatory;
    overflow-y: scroll;   
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.main::-webkit-scrollbar{
    display: none;
  }

.tOne{ 
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;


    scroll-snap-align: center;
    position: relative; 
 
}
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    

}

.title{
    margin-top: 110px;
    font-weight: 700;
   
    line-height: 57.5px;
    align-content: center;
    align-items: center;
    align-self: center;
    color: #FFFFFF;
}
.subTxt{
    margin-top: 10px;
    font-weight: 400;
    font-size: 22px;
    color: #FFFFFF;
    
}
.titleB{
    margin-top: 110px;
    font-weight: 700;
    font-size: 50px;
    line-height: 57.5px;
    align-content: center;
    align-items: center;
    align-self: center;
    color: #000000;
}
.subTxtB{
    margin-top: 10px;
    font-weight: 400;
    font-size: 22px;
    color: #000000;
    
}
.btnRow{
    margin-top: 43px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    width:80%;
    max-width: 440px;
    height: 90px;
    align-items: center;
    
}
.btnRow2{
    margin-top: 43px;
    display: flex;
   justify-content: center;
   max-width:80%;
   width: 400px;
    height: 30px;    
}

.whiteBtn{
    display: flex;
    font-size: 16px;
    font-weight: 700;    
    min-width: 180px;
    max-width: 180px;
    border-radius: 6px;
    height: 30px;
    line-height: 30px;
   
    color: #000000;

      
    justify-content: center;       
    background-color: rgba(255, 255, 255, .8);
   
   backdrop-filter:  blur(1.5px);
   cursor: pointer;
   align-items: center;

   transition: ease-out;
    transition-duration: .3s;
  }
.whiteBtn:hover{ 
    background-color: #FFFFFF;
    transition: ease-out;
    transition-duration: .3s;
}
.blackBtn{
    
    font-size: 16px;
    font-weight: 700;    
    min-width: 180px;
    max-width: 180px;
    border-radius: 6px;  
    background-color: rgba(0, 0, 0, .8);
    color: #FFFFFF;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;

    transition: ease-out;
    transition-duration: .3s;
}
.blackBtn:hover{ 
    background-color: #000000;
    transition: ease-out;
    transition-duration: .3s;
}
.container2{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
   
 align-self: center;
 justify-self: center;

}
.arrows{
  
}

.title2{
    margin-top: 50px;
    font-weight: 700;
    font-size: 50px;
    line-height: 57.5px;
    align-content: center;
    align-items: center;
    align-self: center;
    color: #FFFFFF;
}
.fContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 0;
    position: absolute;
}
.footer{
    justify-self: center;
    align-self: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    color: #FFFFFF;
    
    display: flex;
    width: 80%;
    max-width: 380px;
    justify-content: space-between;
    margin-bottom: 16px;
    
   
}

.scrollPad{
   position: absolute;
    background-color: red;
    color: white;
  
    width: 30px;
    height: 80vh;
    z-index: 1;
    bottom: 10%;
    

/*
 bottom: 0;

*/
   
}