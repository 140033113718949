.main{

}
.page{
    top: 0;
    margin-top: 0;
    width: 100%;
    min-height: min-content;
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;

    scroll-snap-type: y mandatory;
    overflow-y: scroll;   
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */


}
.tOne{
    height: max-content;

    max-width: 100vw;
 margin-top: 100px;

   
    position: relative; 
    display: flex;
    flex-direction: column;
}
.tFoot{
    height: 500px;

    max-width: 100vw;
 margin-top: 100px;

   
    position: relative; 
    display: flex;
    flex-direction: column;
}
.splitRow2{
    margin-top: 120px;
    width: 98%;
    max-width: 1200px;
    min-height: 380px;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    justify-content: space-evenly; 
   
}
@media only screen and (max-width: 757px) {
    .splitRow2{
        margin-top: 60px;
    }
}


.splitRow3{
    margin-top: 33px;
    width: 98%;
    max-width: 1200px;
    min-height: 380px;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    justify-content: space-evenly; 
   
}
.column{
    width: 50%;
    width: 370px;

    max-height: max-content;
    display: flex;
    flex-direction: column;
}
.planT{
    margin-top: 16px;
    font-weight: 700;
    font-size: 27px;    
}
.planT2{
    margin-top: 30px;
    font-weight: 700;
    font-size: 27px; 
    text-align: center;   
}
.txt{
    font-weight: 400;
    font-size: 16px;
    margin-top: 18px;
}
@media only screen and (max-width: 757px) {
    .txt{
        margin-top: 12px;
    }
}
.txt2{
    font-weight: 400;
    font-size: 42px;
    margin-top: 12px;
    margin-left: 6%;
    margin-right: 6%;
}
@media only screen and (max-width: 460px) {
    .txt2{
        font-size: 30px;
    }
}
.txtP{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 12px;
    margin-bottom: 12px;
}
.subCont{
   
    margin-top: auto;
 }
 .cardT{
     font-weight: 700;
     font-size: 24px;
     margin-top: 6px;
     margin-bottom: 9px;
 }

 .rBtn{
     width: 80%;
    font-weight: 400;
    font-size: 16px;
    color: white;
    height: 27px;
    border-radius: 6px;
    background-color: #0062CC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    line-height: 27px;
    cursor: pointer;
    padding-top: 2px;
    padding-bottom: 2px;

}
.rBtn2{
    width: fit-content;
    padding-left: 21px;
    padding-right: 21px;
    font-weight: 450;
    font-size: 16px;
    color: white;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 6px;
    background-color: #0062CC;
    display: flex;
    justify-content: center;
    align-items: center;
    
    line-height: 27px;
    cursor: pointer;
    margin-left: 30px;
    text-align: center;
   
   

}
@media only screen and (max-width: 757px) {
    .rBtn2{

        width: auto;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 1px;
        padding-bottom: 1px;
    }
}
.rBtn3{
    width: 100%;
   font-weight: 400;
   font-size: 16px;
   color: white;
   height: 27px;
   border-radius: 6px;
   background-color: #0062CC;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 9px;
   line-height: 27px;
   cursor: pointer;
   margin-bottom: 50px;
   padding-top: 2px;
   padding-bottom: 2px;

}
.subTxt{
    font-size: 14px;
    font-weight: 400;
    color: #0062CC;
    display: flex;
    justify-content: center;
    margin-top: 12px;
    cursor: pointer;

}
@media only screen and (max-width: 757px) {
    .subTxt{
        margin-top: -6px;
        margin-bottom: 2px;
    }
}
.stickFooter{
    bottom: 0;
    position: absolute;
    width: 100%;
   
    height: 100px;
    align-self: center;
    border-top: .5px solid rgba(29, 29, 29, 0.255);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
   
}
@media only screen and (max-width: 757px) {
    .stickFooter{
      height: 100px;
    }
}
.footRow{
   
    width: 820px;
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

}
.footTitle{
    font-weight: 700;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 12px;
}
@media only screen and (max-width: 550px) {
    .footTitle{
    font-size: 24px;
    }
}
.start{
  
    
    font-size: 12px;
    font-weight: 400;
}
.price{
    font-weight: 700;
    font-size: 24px;
}
.rowCont{
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 3px;
    margin-bottom: 3px;
}


.txtCont{
    margin-top: 100px;

}

.txt2{
    font-weight: 400;
    font-size: 42px;
    text-align: center;
}
.subTxt2{
    font-size: 12px;
    font-weight: 400;
    color: #0062CC;
    display: flex;
    justify-content: center;
    margin-top: 23px;
    cursor: pointer;
}
